<template>
  <div id="add-view">
    <Snackbar :snackbar="snackbar" />
    <v-sheet width="650" height="90vh" class="pa-4 mx-auto d-flex flex-column justify-center">
      <div class="text-h5 mb-3">{{ this.id ? "Edit" : "Add" }} Student</div>
      <v-form ref="signUpForm" lazy-validation>
        <v-row>
          <v-col cols="12" sm="6" md="6" class="pb-0">
            <v-text-field v-model="firstName" :rules="firstNameRules" required label="First Name" outlined dense />
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pb-0">
            <v-text-field v-model="lastName" :rules="lastNameRules" required label="Last Name" outlined dense />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6" class="pb-0">
            <!-- <v-text-field v-model="currentStandard" required label="Current Standard" outlined dense /> -->
            <v-autocomplete
              outlined
              dense
              label="Current Standard"
              :items="standards"
              item-text="name"
              item-value="name"
              v-model="currentStandard"
            />
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pb-0">
            <v-autocomplete
              outlined
              dense
              label="Occupation"
              :items="occupations"
              item-text="name"
              item-value="name"
              v-model="occupation"
            />
          </v-col>
        </v-row>
        <v-row v-if="occupation == 'Other'">
          <v-col cols="12" class="py-0">
            <v-text-field v-model="otherOccupation" label="Occupation" outlined dense />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-autocomplete
              outlined
              dense
              label="School"
              :items="schools"
              item-text="name"
              item-value="name"
              v-model="school"
              return-object
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-text-field v-model="email" :rules="emailRules" label="Email" outlined dense autocomplete="username" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="passwordRules.concat(matchPasswordRule)"
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              autocomplete="new-password"
              @click:append="showPassword = !showPassword"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <v-text-field
              v-model="confirmPassword"
              :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="passwordRules.concat(matchPasswordRule)"
              :type="showConfirmPassword ? 'text' : 'password'"
              label="Confirm Password"
              @click:append="showConfirmPassword = !showConfirmPassword"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-radio-group v-model="gender" row>
          <template v-slot:label>
            <div class="text-subtitle-1">Gender</div>
          </template>
          <v-radio v-for="n in ['male', 'female', 'others']" :key="n" :label="titleize(n)" :value="n" />
        </v-radio-group>
        <v-row>
          <v-col cols="12" sm="6" md="6" class="pb-0">
            <v-menu
              ref="dob"
              v-model="dobMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateOfBirth"
                  label="Date of Birth"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                  class="dob"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateOfBirth"
                :active-picker.sync="activePicker"
                :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                min="1900-01-01"
                @change="saveDoB"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pb-0">
            <v-text-field
              v-model="phoneNumber"
              :rules="phoneNumberRules"
              maxlength="10"
              required
              label="Phone Number"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-btn class="float-right" color="primary" @click="handleSavingStudent" depressed>Save</v-btn>
        <v-btn class="float-right mr-1" color="secondary" to="/admin/student/list" depressed>Cancel</v-btn>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import Snackbar from "../../../components/Snackbar.vue";
import snackbarMixin from "../../../mixins/snackbar";
import { adminApiAuth, adminApi } from "../../../utils/api";
import { getDateStr } from "../../../utils/helper";
// import occupations from "../../../data/occupations";
const passwordLengthValidator = (v) => !v || (v && v.length >= 6) || "Password must be at least 6 characters long";
export default {
  name: "Student-Add",
  components: {
    Snackbar,
  },
  mixins: [snackbarMixin],
  props: ["id"],
  data: () => ({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    gender: "",
    dateOfBirth: null,
    phoneNumber: "",
    currentStandard: "",
    occupation: "",
    otherOccupation: "",
    dobMenu: false,
    activePicker: null,
    showPassword: false,
    showConfirmPassword: false,
    school: "",
    emailRules: [
      // (v) => !!v || "Email is required",
      (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,7})+$/.test(v) || "Email must be valid",
    ],
    passwordRules: [
      //(v) => !!v || "Password is required",
      passwordLengthValidator,
    ],
    phoneNumberRules: [(v) => /^(?:\(\d{3}\)|\d{3}[.-]?)\d{3}[.-]?\d{4}$/.test(v) || "Phone Number is required"],
    firstNameRules: [
      (v) => !!v || "First Name is required",
      (v) => !v || /^[A-Za-z]*$/.test(v) || "First Name must be valid",
    ],
    lastNameRules: [
      (v) => !!v || "Last Name is required",
      (v) => !v || /^[A-Za-z]*$/.test(v) || "First Name must be valid",
    ],
    // occupations: occupations.occupations.map((x) => {
    //   return { name: x.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()) };
    // }),
    occupations: [],
    standards: [],
    schools: [],
    snackbar: {
      show: false,
      status: "",
      message: "",
    },
  }),
  watch: {
    dobMenu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  computed: {
    matchPasswordRule() {
      return this.password === this.confirmPassword || "Password must match";
    },
  },
  created() {
    this.getSources();
    this.getSchools();
  },
  async mounted() {
    if (this.id) {
      const response = await adminApi.get(`/api/student/${this.id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access-token"),
        },
      });
      if (response.data.success) {
        var d = response.data.data[0];
        var props = {
          firstName: "first_name",
          lastName: "last_name",
          email: "email",
          gender: "gender",
          dateOfBirth: "dob",
          phoneNumber: "phone_number",
          currentStandard: "current_standard",
          occupation: "occupation",
          otherOccupation: "otherOccupation",
          id: "_id",
        };
        Object.keys(props).forEach((p) => {
          this.$set(this, p, d[props[p]]);
        });
        this.$set(this, "school", { name: d["school_name"], id: d["school"] });
        this.$set(this, "passwordRules", [passwordLengthValidator]);
      }
    }
  },
  methods: {
    async getSources() {
      try {
        const res = await adminApi.get("/api/index/app-sources", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access-token"),
          },
        });
        if (res.data.success) {
          this.occupations = [{ id: "", name: "Other" }].concat(res.data.data);
          this.standards = res.data.data;
        }
      } catch (err) {
        console.log("err", err);
      }
    },
    async getSchools() {
      try {
        const res = await adminApi.get("/api/school", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access-token"),
          },
        });
        if (res.data.success) {
          this.schools = res.data.data.map((x) => ({ name: x.name, id: x._id }));
        }
      } catch (err) {
        console.log("err", err);
      }
    },
    async handleSavingStudent() {
      if (!this.$refs.signUpForm.validate()) return;

      try {
        const methodName = this.id ? "put" : "post";
        const url = this.id ? `/update-student/${this.id}` : "/register-student-web";
        const dataToSave = {
          first_name: this.firstName,
          last_name: this.lastName,
          password: this.password,
          dob: this.dateOfBirth,
          gender: this.gender,
          phone_number: this.phoneNumber,
          occupation: this.occupation,
          otherOccupation: this.otherOccupation,
          current_standard: this.currentStandard,
          school_name: this.school.name,
          school: this.school.id,
          createdDateStr: getDateStr()
        };
        if (this.email) {
          dataToSave.email = this.email;
        }
        const response = await adminApiAuth[methodName](url, dataToSave);
        if (response.data.success) {
          this.callSuccess(response.data.message);
          setTimeout(() => {
            const actionName = this.id ? "updateStudent" : "addStudent";
            this.$store.dispatch(actionName, response.data.data[0]);
            this.$router.push("/admin/student/list");
          }, 1000);
        } else {
          this.callError(response.data.message);
        }
      } catch (e) {
        console.log(e);
        if (e?.response?.data.message === "Invalid data.") {
          this.callError(e?.response?.data.error.map((e) => Object.values(e).join(",")).join("<br/>"), true);
        } else if (e?.response?.data.message) {
          this.callError(e?.response?.data.message);
        } else {
          this.callError(e.message);
        }
      }
    },
    titleize(v) {
      return v.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    },
    saveDoB(date) {
      this.$refs.dob.save(date);
    },
  },
};
</script>
