<template>
  <div class="sunburst">
    <div class="svg-container">
      <v-progress-circular
        style="display: block"
        class="show-loader"
        v-if="showLoader"
        indeterminate
        color="primary"
        :size="70"
        :width="7"
      ></v-progress-circular>
      <svg id="sunburst-svg"></svg>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import { adminApi } from "../utils/api";
export default {
  name: "Sunburst",
  props: {},
  data: () => ({
    nodeMap: null,
    width: 500,
    height: 500,
    showLoader: true,
    processed: {},
  }),
  watch: {},
  mounted() {
    this.getJsonData();
    setTimeout(() => {
      this.showLoader = false;
      const data = this.formatData();
      this.getSunburst(data);
    }, 2000);
  },
  methods: {
    getJsonData() {
      adminApi
        .get("/api/index/get-json-data", {
          // TODO: this needs to be improved
          // Since default localstorage of axios was taking old value of token
          // so had to add the auth header in the direct request
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access-token"),
          },
        })
        .then((res) => {
          this.nodeMap = res.data.jsonData;
        });
    },
    setChildren(nodeId) {
      if (!this.processed[nodeId]) {
        this.processed[nodeId] = 1;
        const children = this.nodeMap.links.filter((x) => x.source == nodeId);
        if (children.length > 0) {
          return children.map((x) => {
            const obj = { name: x.target };
            const children2 = this.setChildren(x.target);
            if (children2 && children2.length > 0) {
              obj.children = children2;
              //obj.value = children2.length;
            } else {
              obj.value = 1;
            }
            return obj;
          });
        } else {
          return [];
        }
      }
    },
    formatData() {
      const ssData = { name: "careermap", children: [] };
      const skips = ["12th (H.S.C.)", "8th", "10th (S.S.C.)", "Photo Editing", "Photoshop Master (2 months)"];
      Object.keys(this.nodeMap.nodes).forEach((nodeId) => {
        const node = this.nodeMap.nodes[nodeId];
        if (skips.indexOf(node.id) == -1) {
          const dNode = { name: node.id, children: [] };
          dNode.children = (this.setChildren(node.id) || []).filter((x) => x.children && x.children.length > 0);
          // dNode.value = dNode.children.length;
          if (dNode.children.length > 0) {
            ssData.children.push(dNode);
          } else {
            dNode.value =1;
          }
        }
      });
      console.log(ssData);
      return ssData;
    },
    partition(data) {
      const root = d3
        .hierarchy(data)
        .sum((d) => d.value)
        .sort((a, b) => b.value - a.value);
      return d3.partition().size([2 * Math.PI, root.height + 1])(root);
    },
    getSunburst(data) {
      const svgContainer = d3.select(".svg-container").node();
      const width = Math.min(this.width, svgContainer.offsetWidth);
      const height = Math.min(this.height, svgContainer.offsetHeight);
      const radius = Math.min(width, height) / 6;
      const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, data.children.length + 1));
      const format = d3.format(",d");
      const arc = d3
        .arc()
        .startAngle((d) => d.x0)
        .endAngle((d) => d.x1)
        .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
        .padRadius(radius * 1.5)
        .innerRadius((d) => d.y0 * radius)
        .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1));
      const root = this.partition(data);

      root.each((d) => (d.current = d));

      // const svg = d3.create("svg").attr("viewBox", [0, 0, width, width]).style("font", "10px sans-serif");
      const svg = d3.select("#sunburst-svg");
      svg.attr("viewBox", [0, 0, width, height]);
      svg.attr("pointer-events", "all");
      svg.attr("width", Math.min(width, height));
      const g = svg.append("g").attr("transform", `translate(${width / 2},${width / 2})`);

      const path = g
        .append("g")
        .selectAll("path")
        .data(root.descendants().slice(1))
        .enter()
        .append("path")
        .attr("fill", (d) => {
          while (d.depth > 1) d = d.parent;
          return color(d.data.name);
        })
        .attr("fill-opacity", (d) => (arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0))
        .attr("pointer-events", (d) => (arcVisible(d.current) ? "auto" : "none"))

        .attr("d", (d) => arc(d.current));

      path
        .filter((d) => d.children && d.children.length)
        .style("cursor", "pointer")
        .on("click", clicked);

      path.append("title").text(
        (d) =>
          `${d
            .ancestors()
            .map((d) => d.data.name)
            .reverse()
            .join("/")}\n${format(d.value)}`
      );

      const label = g
        .append("g")
        .attr("pointer-events", "none")
        .attr("text-anchor", "middle")
        .style("user-select", "none")
        .selectAll("text")
        .data(root.descendants().slice(1))
        .enter()
        .append("text")
        .attr("dy", "0.35em")
        .attr("fill-opacity", (d) => +labelVisible(d.current))
        // .attr('fill', '#fff')
        .attr("transform", (d) => labelTransform(d.current))
        .text((d) => d.data.name);

      const parent = g
        .append("circle")
        .datum(root)
        .attr("r", radius)
        .attr("fill", "none")
        .attr("pointer-events", "all")
        .on("click", clicked);

      function clicked(event, p) {
        parent.datum(p.parent || root);

        root.each(
          (d) =>
            (d.target = {
              x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
              x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
              y0: Math.max(0, d.y0 - p.depth),
              y1: Math.max(0, d.y1 - p.depth),
            })
        );

        const t = g.transition().duration(750);

        // Transition the data on all arcs, even the ones that aren’t visible,
        // so that if this transition is interrupted, entering arcs will start
        // the next transition from the desired position.
        path
          .transition(t)
          .tween("data", (d) => {
            const i = d3.interpolate(d.current, d.target);
            return (t) => (d.current = i(t));
          })
          .filter(function (d) {
            return +this.getAttribute("fill-opacity") || arcVisible(d.target);
          })
          .attr("fill-opacity", (d) => (arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0))
          .attr("pointer-events", (d) => (arcVisible(d.target) ? "auto" : "none"))

          .attrTween("d", (d) => () => arc(d.current));

        label
          .filter(function (d) {
            return +this.getAttribute("fill-opacity") || labelVisible(d.target);
          })
          .transition(t)
          .attr("fill-opacity", (d) => +labelVisible(d.target))
          .attrTween("transform", (d) => () => labelTransform(d.current));
      }

      function arcVisible(d) {
        return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
      }

      function labelVisible(d) {
        return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
      }

      function labelTransform(d) {
        const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
        const y = ((d.y0 + d.y1) / 2) * radius;
        return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
      }
      // d3.selectAll("#sunburst-svg > *").remove();
      // d3.select("#sunburst-svg").append(() => svg.node());
      return svg.node();
    },
  },
  beforeDestroy() {
    d3.selectAll("#sunburst-svg > *").remove();
  },
};
</script>

<style lang="scss">
.sunburst .svg-container {
  text-align: center;
}
.mobileView #sunburst-svg{
  height: 340px;
}
</style>